const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

const InsightService = {
  getInsights: async (answers) => {
    try {
   
      const response = await fetch(`${API_BASE_URL}/chat/insights`, {
        method: "POST",
        credentials: "include",  
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(answers),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch insights");
      }
      return data;
    } catch (error) {
      console.error("Error fetching insights: ", error);
      return null; 
    }
  },
};

export default InsightService;
