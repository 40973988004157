import React, { useState } from "react";
import rehaGrettingImg from "../../images/reha-gretting-md.png";
import vectorPhyciallyImg from "../../images/vector-phycially.svg";
import vectorSociallyImg from "../../images/vector-socially.svg";
import vectorEmotionallyImg from "../../images/vector-emotionally.svg";
import circleShadowImg from "../../images/circle-shadow.svg";
import dotsImg from "../../images/dots.svg";
import { Doughnut } from "react-chartjs-2";
import {
   Chart as ChartJS,
   ArcElement,
   Tooltip,
   Legend,
} from "chart.js";
import Sidebar from "../comman/Sidebar";
import SignOutModal from "../modal/SignOutModal";

import { SidebarProvider } from "../comman/SidebarProvider.js";
import Header from "../comman/Header.js";
ChartJS.register(ArcElement, Tooltip, Legend);
const NoInsightsComponent = () => {
   const [showSignOutModal, setShowSignOutModal] = useState(false);

   return <>
      <SidebarProvider>
         <div className="flex flex-col overflow-hidden" style={{maxHeight:'100vh'}}>
            <Header />
            <main className="relative xs:mt-[117px] 2xl:mt-[60px] ">
               <span className="bg-insights absolute top-0 left-0 w-full h-[200px] z-[-1]"></span>
               <div className="container">
                  <div className="w-100 h-[25px]"></div>
                  <section className="gretting relative flex justify-center">
                     <div className="relative xs:w-full lg:max-w-[80%]">
                        <div className="bg-blue-900 w-full rounded-[20px] relative overflow-hidden relative z-1">
                           <div className="bg-center bg-no-repeat flex flex-row bg-[url('../images/circles.png')] h-full ">
                              <div className="flex items-end xs:ml-[0px] lg:ml-[18px]">
                                 <img src={rehaGrettingImg} className="max-w-[120px]" alt="" />
                              </div>
                              <div className="flex items-center xs:pl-5 lg:pl-4 flex-1 xs:pr-8 lg:pr-14 py-5">
                                 <p className="text-white text-[17px] leading-snug">
                                    Please share with Rhea the parental challenge you are experiencing and the age of your child to see insights here.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <div className="w-100 h-[20px]"></div>
                  <section>

                     <div className="bg-shade-1 flex xs:flex-col lg:flex-row items-center xs:min-h-[100%] lg:min-h-[316px] relative mb-5 rounded-[20px]">
                        <div className="relative min-w-[360px] flex items-center justify-center xs:pt-10 lg:pt-0">
                           <img src={vectorEmotionallyImg} className="relative z-[2]" alt="" />
                           <img src={circleShadowImg} className="absolute top-0 left-0 right-0 bottom-0 m-auto z-[1]" alt="" />
                        </div>
                        <span className="absolute top-0 left-0 m-auto z-[0]">
                           <img src={dotsImg} alt="" />
                        </span>
                        <div className="flex-1 w-full xs:px-6 lg:px-[52px] xs:py-10 lg:py-4">
                           <h2 className="font-bold text-[24px] mb-4">Emotionally</h2>
                           <ul className="block">
                              <li className="mb-2">
                                 <span className="w-full block h-[16px] h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                              <li className="mb-2">
                                 <span className="w-full block h-[16px] h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                              <li className="mb-2">
                                 <span className="w-full block h-[16px] h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                              <li className="mb-2">
                                 <span className="w-full block h-[16px] h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                              <li className="mb-2">
                                 <span className="w-[50%] block h-[16px] h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="grid xs:grid-cols-1 lg:grid-cols-[68%_30%] gap-5">

                        <div className="bg-shade-2 w-full flex xs:flex-col lg:flex-row items-center xs:min-h-[100%] lg:min-h-[316px] relative mb-5 rounded-[20px]">
                           <div className="relative min-w-[360px] flex items-center justify-center xs:pt-10 lg:pt-0">
                              <img src={vectorSociallyImg} className="relative z-[2]" alt="" />
                              <img src={circleShadowImg} className="absolute top-0 left-0 right-0 bottom-0 m-auto z-[1]" alt="" />
                           </div>
                           <span className="absolute top-0 left-0 m-auto z-[0]">
                              <img src={dotsImg} alt="" />
                           </span>
                           <div className="flex-1 xs:px-6 lg:px-[52px] xs:py-10 lg:py-4">
                              <h2 className="font-bold text-[24px] mb-4">Socially</h2>
                              <ul className="block">
                                 <li className="mb-2">
                                    <span className="w-full block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                                 </li>
                                 <li className="mb-2">
                                    <span className="w-full block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                                 </li>
                                 <li className="mb-2">
                                    <span className="w-full block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                                 </li>
                                 <li className="mb-2">
                                    <span className="w-full block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                                 </li>
                                 <li className="mb-2">
                                    <span className="w-[50%] block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                                 </li>
                              </ul>
                           </div>
                        </div>

                        <div className="bg-shade-3 w-full flex flex-col items-center xs:min-h-[100%] lg:min-h-[316px] relative mb-5 rounded-[20px]">
                           <div className="relative w-[190px] flex items-center h-full justify-center mx-14 xs:pt-10 lg:pt-0">
                              <Doughnut data={{
                                 type: 'doughnut',
                                 datasets: [
                                    {
                                       data: [40, 60],
                                       backgroundColor: [
                                          'rgba(118, 25, 141, 1)',
                                          'rgba(255, 255, 255, 1)',
                                       ],
                                       borderColor: [
                                          'rgba(118, 25, 141, 1)',
                                       ],
                                       borderWidth: 1,
                                    },
                                 ],
                              }} options={{
                                 responsive: true,
                                 cutout: '72%',
                                 plugins: {
                                    legend: { display: false },
                                    tooltip: { enabled: false },
                                 },
                                 animation: {
                                    duration: 2000,
                                    easing: 'easeInOutQuad',
                                    animateScale: false,
                                    animateRotate: true,
                                 },
                              }} />
                              <span className="w-[50%] block h-[14px] rounded-full bg-[#000000] opacity-[5%] absolute top-0 bottom-0 right-0 left-0 m-auto"></span>
                           </div>
                           <span className="absolute top-0 left-0 m-auto z-[0]">
                              <img src={dotsImg} alt="" />
                           </span>
                        </div>
                     </div>

                     <div className="bg-shade-3 flex xs:flex-col lg:flex-row items-center xs:min-h-[100%] lg:min-h-[316px] relative mb-5 rounded-[20px]">
                        <div className="relative min-w-[360px] flex items-center justify-center xs:pt-10 lg:pt-0">
                           <img src={vectorPhyciallyImg} className="relative z-[2]" alt="" />
                           <img src={circleShadowImg} className="absolute top-0 left-0 right-0 bottom-0 m-auto z-[1]" alt="" />
                        </div>
                        <span className="absolute top-0 left-0 m-auto z-[0]">
                           <img src={dotsImg} alt="" />
                        </span>
                        <div className="flex-1 xs:px-6 lg:px-[52px] xs:py-10 lg:py-4">
                           <h2 className="font-bold text-[24px] mb-4">Physically</h2>
                           <ul className="block">
                              <li className="mb-2">
                                 <span className="w-full block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                              <li className="mb-2">
                                 <span className="w-full block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                              <li className="mb-2">
                                 <span className="w-full block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                              <li className="mb-2">
                                 <span className="w-full block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                              <li className="mb-2">
                                 <span className="w-[50%] block h-[14px] rounded-full bg-[#000000] opacity-[5%]"></span>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </section>
               </div>

            </main>

         </div>
         <div class="bg-gradient-to-t from-white/100 to-white/20 w-full z-[2] absolute left-0 bottom-0 h-[70px]"></div>
         <SignOutModal
            isOpen={showSignOutModal}
            onClose={() => setShowSignOutModal(false)}
            // onConfirm={handleSignOut}
         />
         <Sidebar setSignOutModal={setShowSignOutModal} />
      </SidebarProvider>
      {/* <Sidebar/> */}
   </>
}

export default NoInsightsComponent;