import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '../../config/firebase';
import { logEvent } from "firebase/analytics";
import WelcomeSection from './WelcomeSection';
import SoundsGoodSection from './SoundsGoodSection';
import IntroSection from './IntroSection';

const WelcomeMain = () => {
   const navigate = useNavigate();
   const [step, setStep] = useState('welcome');
   useEffect(() => {
      if (localStorage.getItem("isShowWelcome") === "true") {
         navigate("/")
      }
      logEvent(analytics, "welcome_page_view", { page: window.location.pathname, category: "onboarding_pages" });
      const fetchSignInStatus = async () => {
         const response = await fetch(
           `${process.env.REACT_APP_API_BASE_URL}/auth/status`,
           {
             credentials: "include",
           }
         );
       
         const data = await response.json();
         localStorage.setItem("chat_id", data.chat_id);
       };
   
       fetchSignInStatus();
   }, [navigate])

   const handleNavigation = (nextStep) => {
      setStep(nextStep);
   };

   const handleStart = () => {
      localStorage.setItem('isShowWelcome', "true");
      logEvent(analytics, "chat_page_view", { page: window.location.pathname, category: "acquisition_rate" });
      logEvent(analytics, "message_bar_activated", { page: window.location.pathname, category: "adoption_rate" });
      navigate("/");
   };

   return (
      <main className="bg-primary w-full h-[auto] min-h-screen lg:h-full">
         {step === 'welcome' && <WelcomeSection onNext={() => handleNavigation('soundsGood')} />}
         {step === 'soundsGood' && <SoundsGoodSection onNext={() => handleNavigation('intro')} />}
         {step === 'intro' && <IntroSection onStart={handleStart} />}
      </main>
   );
};

export default WelcomeMain;
