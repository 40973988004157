import React from 'react';
import closeIcon from "../../images/close-icon.svg";
import logoutIcon from "../../images/logout-circle-icon.svg";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase.js";
const API_BASE_URL =
   process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

const SignOutModal = ({ isOpen, onClose }) => {

  const generateSessionId = () => {
    return `session_${Math.random().toString(36).substr(2, 9)}_${Date.now()}`;
 };

   const handleSignOut = async () => {
       try {
          const response = await fetch(`${API_BASE_URL}/auth/logout`, {
             method: "GET",
             credentials: "include",
          });
          if (response.ok) {
             const sessionId = generateSessionId();
             logEvent(analytics, "un_authenticated_sessions", {
                session_id: sessionId,
                timestamp: new Date().toISOString(),
                category: "activation_rate",
                page: window.location.pathname,
             });
             localStorage.removeItem("isSignedIn");
             localStorage.removeItem("customer_detail");
             onClose()
            window.location.reload();
 
          } else {
             throw new Error("Failed to sign out");
          }
       } catch (error) {
          console.error("Error signing out:", error);
       }
    };

  if (!isOpen) return null;
  

  return (
    <>
    <div id="no-account" data-modal-backdrop="static" tabIndex="-1"  className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 xs:h-[100%] lg:h-[auto] "+ (isOpen ? "flex" : "hidden")}>
         <div className="relative justify-center w-full max-w-full md:max-w-[500px] xs:h-full md:h-[auto] md:max-h-full bg-white py-16 shadow-2xl xs:px-10 lg:px-20 md:rounded-[20px] animate-fadeInSlide flex items-center">
         <div>
               <div className="flex flex-col items-center">
                  <img src={logoutIcon} className="w-[76px] h-[76px]" alt=""/>
                  <h2 className="font-bold text-[24px] mt-3">Sign out</h2>
               </div>
               <button onClick={onClose} data-modal-hide="signout" className="absolute top-10 right-10">
               <img src={closeIcon} alt=""/>
               </button>
               <div className="h-[32px] w-full"></div>
               <p className="text-center font-semibold text-[18px] mb-3"> Are you sure you want to sign out?</p>
               <p className="text-center">Your conversation with Rhea will not be personalized after you sign out.</p>
               <button onClick={handleSignOut} className="w-full transition-all duration-300 ease-in-out delay-150 bg-gradient-to-l from-pink-800 to-pink-900 h-[60px] flex items-center justify-center rounded-full mt-[40px] text-[18px] font-bold tracking-[-0.3px] hover:bg-gradient-to-r hover:from-pink-800 hover:to-pink-900 text-white shadow-custom-btn">Sign out</button>
               <button onClick={onClose} className="w-full h-[60px] flex items-center justify-center rounded-full mt-[16px] text-[16px] font-semibold tracking-[-0.3px] text-gray-800 border">Cancel</button>
            </div>
         </div>
     </div>
    {isOpen && <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>}
    </>
  );
};

export default SignOutModal;
