import React, { useState } from "react";
import UserService from "../../services/UserService";
import closeIcon from "../../images/close-icon.svg";
import callIcon from "../../images/call.svg";
import PhoneNumberVerificationModal from "./VerifyPhoneNumber";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

function GetPhoneNumberModal({ isOpen, onClose }) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationOpen, setVerificationOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorDuplicatePhone, setErrorDuplicatePhone] = useState("");

    const handleVerify = async (code) => {
        try {
            const response = await fetch(`${API_BASE_URL}/auth/verify_sms_code`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ code }),
            });

            if (!response.ok) {
                throw new Error('Failed to verify code');
            }

            const data = await response.json();

            if (data.verified) {
                console.log('Verification successful:', data);
                handleModalClose();
            } else {
                setErrorMessage("Invalid or expired code. Try again or request for a new one.");
            }
        } catch (error) {
            console.error('Error verifying code:', error);
            setErrorMessage("An error occurred. Please try again.");
        }
    };

    if (!isOpen) {
        return null;
    }

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value.replace(/\D/g, '').slice(0, 10)); // Clean and limit input
    };

    const handleSubmitClick = async () => {
        if (phoneNumber.length === 10) {
            try {
                const updatedProfile = await UserService.updatePhoneNumber(phoneNumber);
                if (updatedProfile.response.toLowerCase() === "ok") {
                    console.log('Phone number updated successfully:', updatedProfile);
                    setVerificationOpen(true);
                } else {
                    console.error(updatedProfile.error);
                    setErrorDuplicatePhone(updatedProfile.error);
                }
            } catch (error) {
                console.error('Error updating phone number:', error);
                setErrorDuplicatePhone('Error updating phone number:', error);

            }
        } else {
            console.error('Phone number must be 10 digits');
            setErrorDuplicatePhone('Phone number must be 10 digits');
        }
    };

    const handleModalClose = () => {
        setVerificationOpen(false);
        setErrorMessage("");
        onClose();
    };

    return (<>
          {isOpen && !verificationOpen &&  (<div id="add-number" data-modal-backdrop="static" tabIndex="-1" aria-hidden="true" className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 xs:h-[100%] lg:h-[auto] " + (isOpen ? "flex" : "hidden")}>
            <div className="relative justify-center w-full max-w-full md:max-w-[540px] xs:h-full md:h-[auto] md:max-h-full bg-white py-16 shadow-2xl xs:px-10 lg:px-20 md:rounded-[20px] animate-fadeInSlide flex items-center">
                <div>
                    <div className="flex flex-col items-center">
                        <img src={callIcon} className="w-[76px] h-[76px]" alt="" />
                        <h2 className="font-bold text-[24px] mt-3">Add your phone number</h2>
                    </div>
                    <button onClick={onClose} data-modal-hide="add-number" className="absolute top-10 right-10">
                        <img src={closeIcon} alt="" />
                    </button>
                    <div className="h-[32px] w-full"></div>
                    <p className="text-center font-medium"> Get parental tips and follow-up insights all via SMS:</p>
                    <div className="flex flex-col mb-4 mt-5">
                        <div className="relative flex items-center">
                            <input type="text"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                placeholder="Enter your phone number"
                                className="bg-white border-1 border-gray-200 rounded-full w-full h-[52px] font-medium focus:border-pink-800 focus:ring focus:ring-pink-100 pl-12" />
                            <span className="absolute font-semibold left-5">+1</span>
                        </div>
                        {errorDuplicatePhone && <p className="text-red-500 text-sm mb-2">{errorDuplicatePhone}</p>}
                    </div>
                    <p className="text-gray-800 text-[14px]">By providing your phone number, you are opting-in-to receive helpful message from Be Human(e). You can always opt-out via text or from your Account Settings.</p>
                    <button disabled={phoneNumber.length !== 10}
                        onClick={handleSubmitClick}
                        className={"w-full transition-all duration-300 ease-in-out delay-150 h-[60px] flex items-center justify-center rounded-full mt-[40px] text-[18px] font-bold tracking-[-0.3px] text-white " + (phoneNumber.length !== 10 ? "bg-gray-800 " : "bg-gradient-to-l from-pink-800 to-pink-900 shadow-custom-btn")}>Submit</button>
                    <button onClick={onClose} className="w-full h-[60px] flex items-center justify-center rounded-full mt-[16px] text-[16px] font-semibold tracking-[-0.3px] text-gray-800 border">Maybe Later</button>
                    <button onClick={onClose} className="w-full flex items-center justify-center rounded-full mt-[16px] text-[16px] font-semibold tracking-[-0.3px] text-gray-800">Never</button>
                </div>
            </div>
        </div>)}
        {verificationOpen && (
            <PhoneNumberVerificationModal
                phoneNumber={phoneNumber}
                isOpen={verificationOpen}
                onClose={handleModalClose}
                onVerify={handleVerify}
                errorMessage={errorMessage} // Pass the errorMessage to the modal
            />
        )}
          {(isOpen || verificationOpen) && (
            <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>
        )}
    </>
    );
}

export default GetPhoneNumberModal;
