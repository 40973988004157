import React from 'react';
import personImg from '../../images/persons.png';
import mindImg from '../../images/mind.svg';
import expressImg from '../../images/express.svg';
import costImg from '../../images/cost.svg';

const SoundsGoodSection = ({ onNext }) => (
  <section className="container h-[auto] lg:h-full px-6 lg:px-4 py-8 lg:py-16">
    <article className="w-full h-full flex flex-col">
      <img src={personImg} className="max-w-[75%] lg:max-w-[40%] mx-auto pt-4 mb-4 lg:mb-16" alt="Person" />
      <p className="text-[20px] mb-10 leading-tight">
        Built by 40+ years of expertise and research in child psychology, we help you:
      </p>
      <section className="inline-grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        <FeatureCard img={mindImg} title="Confidently make parenting decisions" description="grounded in our extensive expert advice" />
        <FeatureCard img={expressImg} title="Express yourself openly and be heard" description="in a safe space without the fear of judgment" />
        <FeatureCard img={costImg} title="Reduce crisis cost" description="by proactively addressing your parental questions and challenges 24/7" />
      </section>
      <button
        onClick={onNext}
        className="transition-all duration-300 ease-in-out bg-gradient-to-l from-pink-800 to-pink-900 h-[64px] flex items-center justify-center rounded-full mt-8 lg:mt-auto text-[24px] tracking-[-0.3px] w-full lg:w-80 text-white shadow-lg mx-auto"
      >
        OK
      </button>
    </article>
  </section>
);

const FeatureCard = ({ img, title, description }) => (
  <div>
    <img src={img} className="mb-2 w-[40px] h-[40px]" alt={title} />
    <p className="text-[24px] leading-[28px]">
      <span className="font-bold">{title}</span> {description}
    </p>
  </div>
);

export default SoundsGoodSection;
