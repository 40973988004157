// SidebarContext.js
import React, { createContext, useState, useContext } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleOpenSidebar = () => setSidebarOpen(true);
  const handleCloseSidebar = () => setSidebarOpen(false);

  return (
    <SidebarContext.Provider
    value={{
      isSidebarOpen,
      handleOpenSidebar,
      handleCloseSidebar,
    }}
  >
    {children}
  </SidebarContext.Provider>
  );
};

// Custom hook for convenience
export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};
