import React from 'react';
import shareIcon from '../../images/share-icon.png';
import expandIcon from '../../images/expand-icon.png';
import learnIcon from '../../images/learn-icon.png';

const IntroSection = ({ onStart }) => (
  <section className="container h-[auto] lg:h-full px-6 lg:px-4 py-8 lg:py-16">
    <article className="w-full h-full flex flex-col">
      <h1 className="text-6xl lg:text-8xl font-bold mb-16">How dose it works?</h1>
      <section class="inline-grid grid-cols-1 gap-10">
        <StepCard step="1" title="Share" description="your thoughts, questions, and challenges with Rhea" icon={shareIcon} />
        <StepCard step="2" title="Expand" description="on the problem by responding to Rhea’s follow-up questions" icon={expandIcon} />
        <StepCard step="3" title="Learn" description="from Rhea’s personalized suggestions to start the healing process" icon={learnIcon} />
      </section>
      <p className="text-[20px] leading-[26px] mt-8">
        Repeat the process and provide more context so together, we find the peace you are looking for.
      </p>
      <button
        onClick={onStart}
        className="transition-all duration-300 ease-in-out bg-gradient-to-l from-pink-800 to-pink-900 h-[64px] flex items-center justify-center rounded-full mt-8 lg:mt-auto text-[24px] tracking-[-0.3px] w-full lg:w-80 text-white shadow-lg mx-auto"
      >
        Let's start
      </button>
    </article>
  </section>
);

const StepCard = ({ step, title, description, icon }) => (
  <div className="flex items-start">
    <div className="w-[32px] h-[32px] rounded-full bg-[#24154A] inline-flex justify-center items-center text-white text-[18px] me-3 shrink-0 font-bold">
      {step}
    </div>
    <div>
      <h1 className="text-[32px] font-bold leading-none mb-3 flex items-center">
        {title} <img src={icon} alt={title} className="ms-3" />
      </h1>
      <p className="text-[24px] leading-[28px]">{description}</p>
    </div>
  </div>
);

export default IntroSection;
