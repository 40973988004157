import React, { useEffect, useState, useRef } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import PaymentService from '../services/PaymentService';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/UserService';
const Payment = () => {

  const customer_detail = JSON.parse(localStorage.getItem('customer_detail'))
  const [subscriptionType, setSubscriptionType] = useState("USD$50Y");
  const [premiumCode, setPremiumCode] = useState("");
  const [premiumCodeValid, setPremiumCodeValid] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  let data = JSON.parse(localStorage.getItem("isSignedIn"))
  const [isSignedIn, setisSignedIn] = useState(data.authenticated);
  const [subscription_id] = useState(data.subscription_id);
  const [userEmail, setUserEmail] = useState(data.email);
  const [clientSecret, setClientSecret] = useState(customer_detail?.client_secret || null);
  const [customerId, setCustomerId] = useState(customer_detail?.customerId || null);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const navigate = useNavigate();
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchSigninStatus = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      if (!subscription_id) {
        const data = await UserService.getSignInStatus()
        setUserEmail(data.email)
        setisSignedIn(data.authenticated)
        localStorage.setItem("isSignedIn", JSON.stringify(data))
        if (data && !customer_detail) {
          const res = await PaymentService.createPaymentIntentAndCustomer(data.email);
          if (res && res.client_secret) {
            localStorage.setItem('customer_detail', JSON.stringify(res))
            setClientSecret(res.client_secret);
            setCustomerId(res.customerId)
          }
        }
      } else {
        navigate("/")
      }
    }

    fetchSigninStatus()

  }, [subscription_id, customer_detail, navigate])

  const handlePremiumCode = async () => {
    if (premiumCode) {
      const response = await PaymentService.getPremiumCodeStatus(premiumCode)
      setPremiumCodeValid(response)
    }

  }

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#FFF',
      colorBackground: '#ffffff',
      colorText: '#374151',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      borderRadius: '4px',
      fontSizeBase: "18px",
      spacingUnit: "8px 16px"
    }
  };
console.log(subscriptionType,"subscriptionType")
  return (

    <main className="bg-gradient-to-t from-pink-900 to-pink-800 w-full h-full">
      <div className="h-full flex flex-col md:flex-row bg-white">
        <div className="w-full bg-white md:w-1/2 border md:px-10 xl:px-14 xl:pl-20 bg-chat-box-light order-1 md:order-1 px-8">
          <div className="flex justify-between items-center mt-10 mb-10">
            <div className='lg:block hidden'>
              <div className='flex items-center'>
                <div className='w-3 mr-2 mt-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                  </svg>
                </div>
                <span><a href="/" className="text-xs font-semibold text-chat-purple">Back to Chat</a></span>
              </div>
            </div>
            <span className="text-xs p-4 text-chat-purple"></span>
            <div className='py-4 text-end'>
              <a href="/" className="text-xs text-chat-purple font-semibold lg:hidden">Back to Chat</a>
              <p className='text-xs text-custom-gray flex gap-1'><span className='max-md:hidden'></span> {isSignedIn ? "You are signed in as " + userEmail : 'You are not signed in'}</p>
            </div>
          </div>
          <div className='md:my-20 mt-10'>
            <div className='text-base text-chat-purple pb-3'>Subscribe to Be Human(e) for</div>
            <div className='flex rounded-xl py-2 px-1 border border-chat-purple w-80'>
              <div className={`relative h-10 w-36 ml-1 mr-4 md:mr-0 rounded-xl 
                ${subscriptionType === "USD$50Y" ? 'bg-chat-purple text-white' : ''}`}>
                <input type="radio" className='opacity-0 z-10 block absolute inset-0 cursor-pointer' id="USD$50Y" value="USD$50Y" checked={subscriptionType === "USD$50Y"} onChange={(e) => setSubscriptionType(e.target.value)} />
                <label className='absolute inset-0 flex items-center justify-center text-sm' htmlFor="USD$50Y">USD $48 per year</label>
              </div>
              <div className={`relative h-10 w-36 md:ml-3 rounded-xl ${subscriptionType === "USD$5M" ? 'bg-chat-purple text-white' : ''} `}>
                <input type="radio" className='opacity-0 z-10 block absolute inset-0 cursor-pointer' id="USD$5M" value="USD$5M" checked={subscriptionType === "USD$5M"} onChange={(e) => setSubscriptionType(e.target.value)} />
                <label className='absolute inset-0 flex items-center justify-center text-sm' htmlFor="USD$5M">USD $5 per month</label>
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-10">

            <div className="flex items-center lg:justify-between w-full order-2 md:order-1">
              <div className="xl:w-1/2 lg:pr-2 max-xl:mr-6">
                <label htmlFor="code" className="block text-sm font-medium text-chat-purple">
                  Discount code
                </label>
                <input
                  type="text"
                  id="code"
                  value={premiumCode}
                  onChange={(e) => {
                    setPremiumCode(e.target.value)
                    setPremiumCodeValid(null)
                  }}
                  className="p-2 h-12 w-full mt-2 text-gray-900 rounded-xl border border-chat-purple bg-transparent"
                  required
                />
              </div>
              <div className="w-1/2 md:w-2/1 md:pl-2 lg:ml-4 max-sm:ml-0 ml-2 mt-6">
                <button className="w-full max-sm:w-36 max-md:w-48 h-12 border border-custom-purple rounded-xl" onClick={() => handlePremiumCode()}>
                  Use this code
                </button>
              </div>
            </div>
            {premiumCodeValid === true ? <span className='pt-2 row order-2 md:order-1 text-green-600'> Discount code is valid.</span> : premiumCodeValid === false ? <span className='pt-2 row order-2 md:order-1 text-red-600'>Discount code is not valid.</span> : ''}
            <div className='order-1 md:order-2 mb-10'>
              <div
                className='text-chat-purple my-10 max-md:mb-4 text-xl font-semibold flex items-center justify-between cursor-pointer md:cursor-default order-1 max-md:w-10/12'
                onClick={() => setIsOpen(!isOpen)}
              >
                <span>✨ Top features <span className='max-md:hidden'>of Plus</span></span>
                <span className='block md:hidden mr-20'>
                  {isOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='w-4' fill='#24154A' style={{ transform: 'rotate(180deg)' }}>
                      <path d="M207.029 381.475c12.497 12.497 32.758 12.497 45.255 0l176-176c12.497-12.497 12.497-32.758 0-45.255s-32.758-12.497-45.255 0L224 306.745 65.372 160.221c-12.497-12.497-32.758-12.497-45.255 0s-12.497 32.758 0 45.255l176 176z" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='w-4' fill='#24154A'>
                      <path d="M207.029 381.475c12.497 12.497 32.758 12.497 45.255 0l176-176c12.497-12.497 12.497-32.758 0-45.255s-32.758-12.497-45.255 0L224 306.745 65.372 160.221c-12.497-12.497-32.758-12.497-45.255 0s-12.497 32.758 0 45.255l176 176z" />
                    </svg>

                  )}
                </span>
              </div>
              <div className={`flex flex-col gap-3 max-md:mt-8 ${isOpen ? 'block' : 'hidden'} md:block`}>
                <div className='flex text-chat-purple items-center'>
                  <div className='w-4 mr-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                    </svg>
                  </div>
                  <span>Unlimited conversation</span>
                </div>
                <div className='flex text-chat-purple items-center'>
                  <div className='w-4 mr-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                    </svg>
                  </div>
                  <span>Parental Insights</span>
                </div>
                <div className='flex text-chat-purple items-center'>
                  <div className='w-4 mr-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                    </svg>
                  </div>
                  <span>Early access to new features</span>
                </div>
                <div className='flex text-chat-purple items-center'>
                  <div className='w-4 mr-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                    </svg>
                  </div>
                  <span>Coming soon: Multiple chat threads and daily checkins</span>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-20 justify-center hidden max-xl:flex'>
            <div className="text-xs max-md:px-0 text-center">© {new Date().getFullYear()} Be Human(e), co.</div>
          </div>

          <div className="sticky top-full flex justify-between items-center h-28 sm:px-0">
            <div className="text-xs max-lg:px-0 max-lg:order-last"><a href="mailto:support@behumane.co">Need help ?</a></div>
            <div className="text-xs max-lg:px-0 max-xl:hidden">© {new Date().getFullYear()} Be Human(e), co.</div>
            <div className="text-xs max-lg:px-0 hidden max-xl:block"><a
              href="https://www.behumane.ai/privacy-policy"
              className="text-xs p-4 max-lg:px-0"
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy Policy
            </a></div>
            <div className="text-xs center absolute left-1/2 transform -translate-x-1/2 p-4 max-lg:px-0 hidden max-xl:block"> <a
              href="https://www.behumane.ai/terms-conditions"
              className="text-xs p-4 max-lg:px-0"
              target="_blank"
              rel="noreferrer noopener"
            >
              Terms & Conditions
            </a></div>
            <div className='max-md:px-3 justify-between max-xl:hidden'>
              <a
                href="https://www.behumane.ai/terms-conditions"
                className="text-xs"
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms & Conditions.
              </a>
              <a
                href="https://www.behumane.ai/privacy-policy"
                className="text-xs ml-1"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 border md:px-10 xl:px-10 xl:pl-40 bg-white order-2 md:order-2 px-8">
          <div className="text-center md:mt-20 mt-10 mb-10">
            <span className="mx-4 text-gray-600 text-3xl">Pay with card</span>
          </div>
          <div className='mt-6'>
            <input
              type="email"
              id="email"
              name="email"
              value={isSignedIn ? userEmail : 'email@example.com'}
              readOnly
              className="w-full border border-gray-300 rounded px-4 py-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 text-gray-700"
            />
          </div>
          {clientSecret &&
            <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
              <CheckoutForm customerId={customerId} subscriptionType={subscriptionType} premiumCode={premiumCode} clientSecret={clientSecret} />
            </Elements>
          }
        </div>
      </div>

    </main>

  )
}

export default Payment
