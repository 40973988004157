import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import rehaGrettingImg from "../../images/reha-gretting-xl.png";
import rehaGrettingMdImg from "../../images/reha-gretting-md.png"
import rightArrowImg from "../../images/right-arrow.svg"
import InSightsTabComponent from "./InSightsTabComponent";
import Sidebar from "../comman/Sidebar";
import SignOutModal from "../modal/SignOutModal";
import Header from "../comman/Header.js";
import { SidebarProvider } from "../comman/SidebarProvider.js";
import InsightService from "../../services/InsightService.js";

gsap.registerPlugin(ScrollTrigger);

const InsightsComponent = () => {
   const [showSignOutModal, setShowSignOutModal] = useState(false);
   const [insightData, setInsightData] = useState(false);
   const [answers, setAnswers] = useState({
      answers: [
         {
            question: "",
            answer: "",
         },
      ],
   });
   const [error, setError] = useState("");
   const [openIndex, setOpenIndex] = useState(0);
   const [loading, setLoading] = useState(false);

   const [isScrolled, setIsScrolled] = useState(false);
   const [isClamped, setIsClamped] = useState(false);
   const [containerWidth, setContainerWidth] = useState("auto");
   const [lastScrollTop, setLastScrollTop] = useState(0);
   const [rowCount, setRowCount] = useState(0);
   const paragraphRef = useRef(null);
   
   useEffect(() => {
      const handleScroll = () => {
         const scrollTop = window.scrollY || document.documentElement.scrollTop;
         const container = document.querySelector(".container");
         setIsClamped(true)

         if (!container) return;
         const width = container.clientWidth;

         if (scrollTop > 100) {
            setContainerWidth(`${width}px`);
            setIsScrolled(true);
         } else if (scrollTop < lastScrollTop) {
            setContainerWidth("auto");
            setIsScrolled(false);
         }
         setLastScrollTop(scrollTop);
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
         window.removeEventListener("scroll", handleScroll);
      };
   }, [lastScrollTop]);

   const handleReadMoreClick = (e) => {
      e.preventDefault();
      setIsClamped(!isClamped);
   };

   useEffect(() => {
      const fetchInsights = async () => {
         try {
            setLoading(true);
            let insightDetail = await InsightService.getInsights(answers);
            setInsightData(insightDetail)
            if (insightDetail?.insights[0]?.follow_up_questions) {
               const firstQuestion = Object.values(
                  insightDetail.insights[0].follow_up_questions
               )[0]; // Get the first question
               setAnswers({
                  answers: [
                     {
                        question: firstQuestion,
                        answer: "",
                     },
                  ],
               });
            }
            setLoading(false);
         } catch (error) {
            setError(error)
            console.error("Error fetching insights:", error);
         }
      };

      if (answers.answers[0].question === "") {
         fetchInsights();
      }

   }, [answers]);

   const toggleAccordion = (index, question) => {
      setOpenIndex((prevIndex) => (prevIndex === index ? null : index));

      if (openIndex !== index) {
         setAnswers({
            answers: [
               {
                  question: question,
                  answer: "",
               },
            ],
         });
         setError("");
      }
   };

   const handleAnswerChange = (value) => {
      setAnswers((prevState) => ({
         answers: [
            {
               ...prevState.answers[0],
               answer: value,
            },
         ],
      }));
      setError("");
   };

   useEffect(() => {
      if (paragraphRef.current) {
        const computedStyle = window.getComputedStyle(paragraphRef.current);
        const lineHeight = parseFloat(computedStyle.lineHeight);
        const elementHeight = paragraphRef.current.clientHeight;
        setRowCount(Math.ceil(elementHeight / lineHeight));
      }
    }, [insightData]);
   const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const currentAnswer = answers.answers[0];
      if (!currentAnswer.answer.trim()) {
         setLoading(false);
         setError("Answer cannot be empty."); // Set validation error
         return;
      }
      try {
         setError("");
         let insightDetail = await InsightService.getInsights(answers);
         setInsightData(insightDetail)
         // Clear the answer field after successful submission
         if (insightDetail?.insights[0]?.follow_up_questions) {
            const firstQuestion = Object.values(
               insightDetail.insights[0].follow_up_questions
            )[0]; // Get the first question
            setAnswers({
               answers: [
                  {
                     question: firstQuestion,
                     answer: "",
                  },
               ],
            });
         }
      } catch (error) {
         setError(error)
         console.error("Error fetching insights:", error);
      } finally {
         setLoading(false); // Hide loader after submission
      }
   };
   return <React.Fragment>
      <SidebarProvider>

         <div className="flex flex-col">
            {loading && (
               <div className="loader">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
               </div>

            )}
            <Header />
            <main className="relative xs:mt-[110px] 2xl:mt-[60px]">
               <span className="bg-insights absolute top-0 left-0 w-full h-[200px] z-[-1]"></span>
               <div className="container relative">
                  <section className="max-w-[460px] min-h-[150px] ml-auto pt-7">
                     <div className="chat-bubble xs:w-full lg:w-[auto]">
                        <div
                           className={`chat-bubble-bg bg-gradient-to-l from-purple-900 to-purple-800 px-5 py-4 ${isScrolled
                                 ? "fixed z-50 left-0 right-0 top-[113px] 2xl:top-[60px] mx-auto rounded-[0px] rounded-b-[20px]"
                                 : "rounded-[20px] rounded-b-[20px]"
                              }`}
                           style={{ width: containerWidth }}  >
                           <p ref={paragraphRef} 
                              className={`text-white text-[17px] leading-snug ${isClamped  && isScrolled? "line-clamp-2" : ""
                                 }`} >
                             {insightData && insightData.problem_statements[0].problem}
                           </p>
                             {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          {rowCount > 4 &&
                           <a href
                              className="text-[#FE93FF] text-[14px] readmore text-end mt-1 "
                              onClick={handleReadMoreClick}
                              style={{ display: isScrolled ? "block" : "none" }} >
                              {isClamped ? "Read More" : "Read Less"}
                           </a>}
                        </div>
                        <span className="text-right xs:text-[12px] lg:text-[14px] text-gray-800 block w-full mt-2">
                           The summary of the challenge you shared with Rhea
                        </span>
                     </div>
                  </section>
                  <div className="w-100 h-[52px]"></div>
                  <section className="gretting relative">
                     <div className="relative">
                        <div className="bg-blue-900 w-full rounded-[20px] relative overflow-hidden z-1">
                           <div className="bg-center bg-no-repeat flex flex-row bg-[url('../images/circles.png')] h-full ">
                              <div className="flex xs:flex-1 lg:flex-[inherit] items-end xs:ml-[-90px] lg:ml-0">
                                 <img src={rehaGrettingImg} alt="" />
                              </div>
                              <div className="flex items-center xs:pl-1 lg:pl-4 flex-1 xs:pr-5 lg:pr-14 py-5">
                                 <p className="text-white xs:text-[16px] lg:text-[18px] leading-snug line-clamp-6">
                                    <span className="text-[#52ECD2]">First of all, the fact that you are here to learn is amazing!</span> <br />
                                    Kudos to you! That means you care. You're not alone in this journey, and it’s a learning process for every parent. On this page, I hope to provide you with an overview of the challenges you have.
                                 </p>
                              </div>
                           </div>
                        </div>
                        <span className="absolute w-[95%] h-[50px] bg-gray-400 bottom-[-10px] left-0 right-0 mx-auto z-[-1] rounded-b-[20px]"></span>
                     </div>
                     <p className="text-[14px] text-gray-800 italic leading-snug mt-5">
                        * Please note this analysis is generated solely based on the limited context you have shared. The more conversation you have with Rhea, the more accurate and comprehensive these insights will be.
                     </p>
                  </section>
                  <div className="w-100 h-[52px]"></div>
                  <section>
                     <ul className="flex flex-nowrap overflow-auto pb-5 font-medium text-center text-gray-500 dark:text-gray-400" id="insights-tab" data-tabs-toggle="#insights-tab-content" role="tablist" data-tabs-active-classes="text-white bg-gradient-to-r from-pink-800 to-pink-900 font-bold shadow-custom-btn" data-tabs-inactive-classes="bg-white border border-gray-200 font-medium hover:bg-gray-50">
                        <li className="me-2">
                           <button className="inline-flex px-[28px] h-[40px] items-center rounded-full" id="breakdown-tab" data-tabs-target="#breakdown" type="button" role="tab" aria-controls="breakdown">
                              Breakdown</button>
                        </li>
                        <li className="me-2">
                           <button className="inline-flex px-[28px] h-[40px] items-center rounded-full" id="statistics-tab" data-tabs-target="#statistics" type="button" role="tab" aria-controls="statistics" aria-selected="false">
                              Statistics
                           </button>
                        </li>
                     </ul>
                     <InSightsTabComponent insightTabData={insightData && insightData.insights[0]} />
                  </section>
                  <div className="w-100 h-[52px]"></div>
               </div>
               <section className="bg-gray-100">
                  <div className="container">
                     <div className="flex flex-col items-center pt-[60px]">
                        <h3 className="font-bold bg-gradient-to-l from-purple-900 to-purple-800 bg-clip-text text-transparent text-[28px] flex items-center mb-2">Next Step <span className="ms-2"><img src={rightArrowImg} alt="" /></span></h3>
                        <p className="text-gray-800 text-center leading-normal">
                           Now, to continue working together on this challenge, I would love to know more about it. <br className="xs:hidden lg:block" />
                           Could you please help me understand:
                        </p>
                     </div>
                     <div className="w-full h-[40px]"></div>
                     <form onSubmit={handleSubmit}>
                        <div id="accordion-flush">
                           {insightData &&
                              Object.entries(
                                 insightData.insights[0].follow_up_questions
                              ).map(([key, question], index) => (
                                 <div key={key}>
                                    <h2 id={`accordion-flush-heading-${index}`}>
                                       <button
                                          type="button"
                                          className="flex items-center justify-between w-full py-5 border-b border-gray-200 gap-3 text-[18px]"
                                          aria-expanded={openIndex === index}
                                          aria-controls={`accordion-flush-body-${index}`}
                                          onClick={() => toggleAccordion(index, question)} // Open/close accordion
                                       >
                                          <div className="flex items-center text-left">
                                             <span className="w-[32px] h-[32px] flex items-center justify-center rounded-full bg-yellow-800 font-bold me-4">
                                                Q
                                             </span>
                                             <span className="flex-1">{question}</span>
                                          </div>
                                          <svg
                                             data-accordion-icon
                                             className={`w-3 h-3 shrink-0 accordion-arrow transition-transform duration-300 ${openIndex === index ? "rotate-180" : ""
                                                }`}
                                             aria-hidden="true"
                                             xmlns="http://www.w3.org/2000/svg"
                                             fill="none"
                                             viewBox="0 0 10 6"
                                          >
                                             <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M9 5 5 1 1 5"
                                             />
                                          </svg>
                                       </button>
                                    </h2>
                                    <div
                                       id={`accordion-flush-body-${index}`}
                                       className={`overflow-hidden transition-[max-height] duration-300 ${openIndex === index ? "max-h-[200px] py-5" : "max-h-0"
                                          }`}
                                       aria-labelledby={`accordion-flush-heading-${index}`}
                                    >
                                       <label
                                          htmlFor={`answer-${key}`}
                                          className="text-[14px] font-medium mb-2 block"
                                       >
                                          Answer
                                       </label>
                                       <textarea
                                          className="bg-white border-1 border-gray-200 w-full rounded-[8px] pl-3 focus:border-pink-800 focus:ring focus:ring-gray-100 shadow-sm placeholder-gray-800 font-medium placeholder:text-[14px] placeholder:font-normal"
                                          name={`answer-${key}`}
                                          id={`answer-${key}`}
                                          cols="100%"
                                          rows="4"
                                          placeholder="Please enter your answer"
                                          value={openIndex === index ? answers.answers[0].answer : ""} // Display the current answer only for the open accordion
                                          onChange={(e) => handleAnswerChange(e.target.value)}
                                       ></textarea>
                                    </div>
                                 </div>
                              ))}
                        </div>

                        {error && <p className="text-red-600 text-sm text-center mt-2">{error}</p>} {/* Display validation error */}
                        <div className="w-full h-[40px]"></div>
                        <div className="flex w-full justify-center">
                           <button
                              type="submit"
                              className="flex xs:w-full justify-center lg:w-[auto] lg:px-[96px] h-[54px] items-center rounded-full text-white bg-gradient-to-r from-pink-800 to-pink-900 font-bold shadow-custom-btn"
                           >
                              Update the Insights
                           </button>
                        </div>
                     </form>
                     <div className="w-full h-[50px]"></div>
                     <section className="gretting relative">
                        <div className="bg-blue-900 w-full rounded-[20px] relative z-[1] overflow-hidden">
                           <div className="bg-center bg-no-repeat flex bg-[url('../images/circles.png')] h-full ">
                              <div className="flex items-end xs:ml-[-50px] lg:ml-0 xs:px-0 lg:px-8">
                                 <img src={rehaGrettingMdImg} alt="" />
                              </div>
                              <div className="flex items-center pl-4 flex-1 xs:pr-6 lg:pr-28 xs:py-6 lg:py-0">
                                 <p className="text-white xs:text-[16px] lg:text-[18px] leading-snug">
                                    Each family is unique, and exploring these questions might guide you toward a deeper understanding. Together, we can continue  this journey one small step at a time.
                                 </p>
                              </div>
                           </div>
                        </div>
                        <span className="absolute w-[95%] h-[50px] bg-gray-400 bottom-[-10px] left-0 right-0 mx-auto z-[0] rounded-b-[20px]"></span>
                     </section>
                     <div className="w-full h-[30px]"></div>
                  </div>
               </section>
            </main>
            <footer className="bg-gray-100">
               <div className="container">
                  <div className="flex xs:flex-col sm:flex-row items-center justify-between py-4">
                     <p className="text-[14px] text-gray-800"> ©{new Date().getFullYear()} Be Human(e), co.</p>
                     <ul className="flex items-center">
                        <li className="inline-block pr-6">
                           <a href="mailto:support@behumane.co" className="text-[14px] underline underline-offset-4 decoration-gray-300">Need Help?</a>
                        </li>
                        <li>
                           <a target="_blank" rel="noreferrer" href="https://www.behumane.ai/privacy-policy" className="text-[14px] underline underline-offset-4 decoration-gray-300">Privacy Policy</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </footer>
         </div>
         <SignOutModal
            isOpen={showSignOutModal}
            onClose={() => setShowSignOutModal(false)}
         />
         <Sidebar setSignOutModal={setShowSignOutModal} />
      </SidebarProvider>
   </React.Fragment>

}
export default InsightsComponent;