const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

const UserService = {
  getProfile: async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/user/profile`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch profile");
      }
      return data;
    } catch (error) {
      console.error("Error fetching profile: ", error);
      return null;
    }
  },

  updatePhoneNumber: async (phoneNumber) => {
    try {
      const response = await fetch(`${API_BASE_URL}/user/phone_number`, {
        method: "PUT",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: phoneNumber }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to update phone number");
      }
      return data;
    } catch (error) {
      console.error("Error updating phone number: ", error);
      return null;
    }
  },
  getSignInStatus: async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/status`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch status");
      }
      return data;
    } catch (error) {
      console.error("Error fetching status: ", error);
      return null;
    }
  },
  getQuestions: async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/user/questions`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch question");
      }
      return data;
    } catch (error) {
      console.error("Error fetching question: ", error);
      return null;
    }
  },
  updateOnboardingSurvey: async (data) => {
    try {
      const response = await fetch(`${API_BASE_URL}/user/onboarding-survey`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (!response.ok) {
        // If the response isn't OK, throw an error with the message from the server
        throw new Error(responseData.message || "Failed to update the onboarding survey");
      }

      // Return the parsed response data
      return responseData;
    } catch (error) {
      console.error("Error updating the onboarding survey: ", error);
      return null; // Return null in case of an error to indicate failure
    }
  },
  // 
};

export default UserService;
