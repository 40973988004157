import React from "react";
import signinIcon from "../../images/signin-icon.svg";
import closeIcon from "../../images/close-icon.svg";
function SignInNudgeModal({ isOpen, onClose }) {

  if (!isOpen) {
    return null;
  }

  const handleSignInClick = () => {
    onClose();
    window.location.href = "/login";
  };

  const handleStayUnauthenticatedClick = () => {
    onClose();
  };

  return (
    <>
    <div id="no-account" data-modal-backdrop="static" tabIndex="-1"  className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 xs:h-[100%] lg:h-[auto] "+ (isOpen ? "flex" : "hidden")}>
         <div className="relative justify-center w-full max-w-full md:max-w-[500px] xs:h-full md:h-[auto] md:max-h-full bg-white py-16 shadow-2xl xs:px-10 lg:px-20 md:rounded-[20px] animate-fadeInSlide flex items-center">
               <div>
                 <div className="flex flex-col items-center">
                     <img src={signinIcon} alt=""/>
                     <h2 className="font-bold text-[24px] mt-3">Don't have an account yet?</h2>
                 </div>
                 <button onClick={onClose} data-modal-hide="no-account" className="absolute top-10 right-10">
                     <img src={closeIcon} alt=""/>
                 </button>
                 <div className="h-[32px] w-full"></div>
                 <p className="text-center font-medium"> Sign up to enjoy personalized advice and easy access to your chat history.</p>
                 <button className="transition-all w-full duration-300 ease-in-out delay-150 bg-gradient-to-l from-pink-800 to-pink-900 h-[60px] flex items-center justify-center rounded-full mt-[40px] text-[18px] font-bold tracking-[-0.3px] hover:bg-gradient-to-r hover:from-pink-800 hover:to-pink-900 text-white shadow-custom-btn"
                //  onMouseEnter={() => setHoverSignIn(true)}
                //  onMouseLeave={() => setHoverSignIn(false)}
                 onClick={handleSignInClick}>Yes! Sign in</button>
              
                 <button className="h-[60px] w-full flex items-center justify-center rounded-full mt-[16px] text-[16px] font-semibold tracking-[-0.3px] text-gray-800 border"
                  // onMouseEnter={() => setHoverStay(true)}
                  // onMouseLeave={() => setHoverStay(false)}
                  onClick={handleStayUnauthenticatedClick} >
                  No, continue without signing in</button>
                 
               </div>
         </div>
     </div>
     {isOpen && <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>}
    
    </>
  );
}
export default SignInNudgeModal;
