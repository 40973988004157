import React, { useState } from 'react'
import Button from './Button'
import { useNavigate } from 'react-router-dom';

const UpgradePlan = () => {
    const navigate = useNavigate();
    let data = JSON.parse(localStorage.getItem("isSignedIn"))
    const [showModal, setShowModal] = useState(false)
    const [isSignedIn] = useState(data.authenticated);
    const [userEmail] = useState(data.email);

    return (
        <main className="bg-gradient-to-t from-pink-900 to-pink-800 w-full h-full">
        <div className='bg-chat-box-light min-h-screen w-screen'>
            <div className="flex justify-between items-center h-36 sm:px-9 md:px-20">
                <span className="text-xs p-4 text-chat-purple"></span>
                <div className='p-4 text-end'>
                    <a href="/" className="text-xs text-chat-purple font-semibold">Back to Chat</a>
                    <p className='text-xs text-custom-gray flex gap-1'><span className='max-md:hidden'></span> {isSignedIn ? "You are signed in as " + userEmail : 'You are not signed in'}</p>
                </div>
            </div>
            <div className="mx-auto flex flex-col items-center sm:px-9 md:px-20 mt-6">
                <p className="font-semibold text-4xl text-chat-purple">Upgrade your plan</p>
                <div className='md:border rounded-2xl border-custom-purple flex flex-col content-center md:mt-16 mt-6 mb-10 max-md:mb-4 p-8'>

                    <div>
                        <p className='text-2xl text-chat-purple font-semibold mb-4'>✨ Plus</p>
                        <span className='text-sm text-chat-purple font-normal'>USD $5 per month</span>
                        <span className='text-xs text-chat-purple font-normal'>(or <strike>$60</strike> $48 per year (20% discount))</span>
                    </div>

                    <div className='mx-0 m-8 flex justify-center max-md:order-last max-md:mb-0'>
                        <Button buttonText={"Choose this plan"} 
                        onClick={() => isSignedIn ? navigate("/payment") : setShowModal(true)} 
                        className='bg-purple-to-pink text-white h-10 w-80 border rounded-xl' />
                    </div>

                    <div className='flex flex-col gap-3 max-md:mt-12'>
                        <div className='flex text-chat-purple items-center'>
                            <div className='w-4 mr-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg></div>

                            <span>Unlimited conversation</span>
                        </div>
                        <div className='flex text-chat-purple items-center'>
                            <div className='w-4 mr-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                            </div>
                            <span>Multiple chat threads</span>
                        </div>
                        <div className='flex text-chat-purple items-center'>
                            <div className='w-4 mr-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                            </div>
                            <span>Daily checkins</span>
                        </div>
                        <div className='flex text-chat-purple items-center'>
                            <div className='w-4 mr-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                            </div>
                            <span>Early access to new features</span>
                        </div>
                        <div className='flex text-chat-purple items-center'>
                            <div className='w-4 mr-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                            </div>
                            <span>Coming soon: Parent Dashboard</span>
                        </div>
                    </div>

                </div>
            </div>
            <div onClick={() => navigate('/')} className='flex justify-center font-semibold text-sm sm:px-9 md:px-20 cursor-pointer'>Cancel</div>
            <div className='mt-10 justify-center hidden max-md:flex'>
                <div className="text-xs max-md:px-0 text-center">© {new Date().getFullYear()} Be Human(e), co.</div>
            </div>

            <div className="sticky top-full flex justify-between items-center h-36 sm:px-9 md:px-20">
                <div className="text-xs p-4 max-md:order-last"><a href="mailto:support@behumane.co">Need help ?</a></div>
                <div className="text-xs p-4 center absolute left-1/2 transform -translate-x-1/2 max-md:px-0 max-md:hidden">© {new Date().getFullYear()} Be Human(e), co.</div>
                <div className="text-xs max-md:px-0 md:hidden"><a
                    href="https://www.behumane.ai/privacy-policy"
                    className="text-xs p-4"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Privacy Policy
                </a></div>
                <div className="text-xs center absolute left-1/2 transform -translate-x-1/2 p-4 max-md:px-0 md:hidden"> <a
                    href="https://www.behumane.ai/terms-conditions"
                    className="text-xs p-4"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Terms & Conditions
                </a></div>
                <div className='max-md:px-3 p-4 justify-between max-md:hidden'>
                    <a
                        href="https://www.behumane.ai/terms-conditions"
                        className="text-xs"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Terms & Conditions.
                    </a>
                    <a
                        href="https://www.behumane.ai/privacy-policy"
                        className="text-xs ml-1"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Privacy Policy
                    </a>
                </div>
            </div>
            <div
                id='ModelContainer'
                className={`fixed inset-0 flex justify-center items-center ${showModal ? '' : 'hidden'}`}>
                <div
                    className='sticky top-full w-full p-2 bg-white shadow-inner border-e-emerald-600 rounded-t-2xl py-5 drop-shadow-[0_0px_35px_#3F154A80]'>
                    <div
                        className='w-full p-3 flex flex-col justify-center items-center'>
                        <div className='w-80'>
                            <div
                                className='font-semibold py-4 leading-10 text-4xl text-chat-purple'>
                                To continue, you need to sign in
                            </div>
                            <div className='text-base leading-4 py-5 text-chat-purple'>
                                We need to know the account for which you are purchasing this plan. That way, next time you login, you are good to go.
                            </div>

                            <div className='mx-0 m-8 flex mt-20 justify-center'>
                                <Button buttonText={"Cool! take me there"} className='bg-purple-to-pink text-white h-10 w-80 border rounded-xl' onClick={() => isSignedIn ? navigate("/payment") : navigate("/login/?p=payment")} />
                            </div>
                            <div onClick={() => navigate('/')} className='flex justify-center font-semibold mb-10 text-sm sm:px-9 md:px-20 cursor-pointer'>No, Thanks</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </main>
    )
}

export default UpgradePlan
