import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";
import UserService from "../../services/UserService";
import IntroPage from "./IntroPage";
import SurveyPage from "./SurveyPage";

const OnboardMain = () => {
  const [onboardingData, setOnboardingData] = useState({
    parenthood: "",
    age: "",
  });
  const [surveyData, setSurveyData] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    logEvent(analytics, "onboarding_page_view", {
      page: window.location.pathname,
      category: "onboarding_pages",
    });

    const fetchQuestions = async () => {
      try {
        const response = await UserService.getQuestions();
        setSurveyData(response.data || []);
      } catch (err) {
        console.error("Error fetching questions:", err);
      }
    };

    fetchQuestions();
  }, []);

  const handleNextStep = (updatedData) => {
    setOnboardingData((prev) => ({ ...prev, ...updatedData }));
    setCurrentStep((prev) => prev + 1);
  };

  // const handleSurveyAnswer = (step, selectedOption) => {
  //   setSurveyData((prev) =>
  //     prev.map((item, index) =>
  //       index === step ? { ...item, selectedOption } : item
  //     )
  //   );
  //   setCurrentStep((prev) => prev + 1);
  //   step === surveyData.length && handleFinalSubmit
  // };

  const handleSurveyAnswer = (step, selectedOption) => {
    // Create a new updatedSurveyData based on the current step
    const updatedSurveyData = surveyData.map((item, index) =>
      index === step ? { ...item, selectedOption } : item
    );
    if (step + 1 === updatedSurveyData.length) {
      handleFinalSubmit(updatedSurveyData);
    } else {
      // Update the state with the updated data and move to the next step
      setSurveyData(updatedSurveyData);
      setCurrentStep((prev) => prev + 1);
    }
  };


  const handleFinalSubmit = async (updatedSurveyData) => {
    setError("");
    const responses = updatedSurveyData.map((question) => ({
      question_id: question.id,
      option_id: question.selectedOption?.id,
      // option_text: question.selectedOption?.option_text,
    }));

    try {
      const payload = {
        ...onboardingData,
        onboarding_completed: true,
        responses,
      };

      const response = await UserService.updateOnboardingSurvey(payload);

      if (!response) {
        throw new Error("Failed to submit the survey");
      }

      navigate("/");
    } catch (err) {
      setSurveyData(updatedSurveyData);
      console.error("Error submitting the survey:", err);

      setError(err.message || "An error occurred. Please try again.");
    }
  };

  return (
    <>
      {currentStep === 0 && (
        <IntroPage
          onboardingData={onboardingData}
          onNext={handleNextStep}
        />
      )}

      {currentStep > 0 && currentStep <= surveyData.length && (
        <SurveyPage
          step={currentStep}
          totalSteps={surveyData.length} // Pass total questions count
          question={surveyData[currentStep - 1]}
          onNext={(selectedOption) =>
            handleSurveyAnswer(currentStep - 1, selectedOption)
          }
          error={error}
        />
      )}
    </>
  );
};

export default OnboardMain;
