import React from "react";
import { Link } from "react-router-dom";
import arrowLeftIcon from "../../images/arrow-left.svg";
import waveIcon from "../../images/wave.svg";
import rehaLarge from "../../images/reha-large.png";
import signInIcon from "../../images/signin-icon.svg";


const SignInCodeComponent = () => {
    return(
         <main className="h-full lg:bg-primary">
         <section className="container h-full">
              <div className="flex w-full h-full flex-col justify-start xs:w-[100%] md:w-[540px] lg:w-[640px] mx-auto ">
               <div className="flex w-full h-[40px]"></div>
               <div className="my-2">
                  <Link to="/" className="flex items-center font-semibold text-gray-800 xs:mb-0 lg:mb-3">
                     <img src={arrowLeftIcon} className="mr-3" alt="" />
                     Back to Chat
                  </Link>
               </div>
              <div className="relative">
                  <div className="absolute z--1 translate-x-[-50%] bottom-0 xs:hidden lg:block">
                     <img src={rehaLarge} alt="" />
                  </div>
                  <div className="lg:bg-white rounded-[20px] xs:py-7 lg:py-[72px] lg:shadow-2xl xs:px-4 lg:px-20 relative z-1">
                     <div className="flex flex-col items-center">
                        <img src={signInIcon} alt=""/>
                        <h2 className="font-bold text-[20px] mt-5">Sign In</h2>
                        <p className="text-gray-800 text-[15px] font-medium text-center mt-1 leading-normal">Be Human(e) is your safe space to share your thoughts, <br className="xs:hidden lg:block" /> feelings, and questions.</p>
                    </div>
                    <div className="w-full h-[60px]"></div>
                    <div className="p-6 border border-pink-800 bg-gradient-to-l from-purple-100 to-pink-100 rounded-lg shadow-sm">
                     <p className="text-[17px] leading-normal">Welcome to BeHuman(e). <img src={waveIcon} alt="" className="inline w-[24px]" /> Rhea is looking forword to chatting with you. Almost there. Please check your email for the access link.</p>
                    </div>
                  </div>
              </div>
              <div className="flex xs:flex-col sm:flex-row items-center justify-between py-4 mt-auto">
               <p className="text-[14px] text-gray-800">©{new Date().getFullYear()} Be Human(e), co.</p>
               <ul className="flex items-center">
                  <li className="inline-block pr-6">
                     <a href="mailto:support@behumane.co" className="text-[14px] underline underline-offset-4 decoration-gray-300">Need Help?</a>
                  </li>
                  <li>
                     <a target="_blank" href="https://www.behumane.ai/privacy-policy" className="text-[14px] underline underline-offset-4 decoration-gray-300" rel="noreferrer">Privacy Policy</a>
                  </li>
               </ul>
            </div>
            </div>
         </section>
      </main>)
    
}

export default SignInCodeComponent;