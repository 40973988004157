import React, { useState, useEffect } from 'react'
import rahaGrettingIcon from "../../images/reha-gretting.svg";
import closeIcon from "../../images/close-icon.svg";
import mailIcon from "../../images/mail-icon.svg";

const SendEmailCopyModal = ({ isOpen, onClose = () => { } }) => {
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/v0";

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    useEffect(() => {
        setIsEmailValid(validateEmail(email));
    }, [email]);

    const handleSend = async () => {
        if (!isEmailValid) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/chat/transcript`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: email }),
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message || "Failed to send message");
            }

            setEmailSent(true);
        } catch (error) {
            console.error('Error:', error);
            setEmailError(error.message || 'An unexpected error occurred');
        }
    };
    if (emailSent) {
        return (
            <React.Fragment>
            <div id="send-copy" data-modal-backdrop="static" tabIndex="-1" className={" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 xs:h-[100%] lg:h-[auto] " + (isOpen ? "flex" : "hidden")}>
                <div className="relative w-full max-w-full md:max-w-[620px] xs:h-full md:h-[auto] md:max-h-full bg-white py-16 shadow-2xl xs:px-10 lg:px-20 md:rounded-[20px] animate-fadeInSlide">

                    <div className="flex flex-col items-center">
                        <img src={mailIcon} alt="" />
                        <h2 className="font-bold text-[20px] mt-3" style={{ marginBottom: '10px' }}>Sent!</h2>
                        <div className="flex flex-col justify-center leading-tight pl-4">
                            <p className="font-medium ] text-[15px]">
                                A copy of your conversation was sent to {email}
                            </p>
                            <p style={{ marginBottom: '10px' }}>Thanks again and hope to see you soon! </p>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a role="button" onClick={onClose} disabled={!isEmailValid} className="transition-all duration-300 ease-in-out delay-150 bg-gradient-to-l from-pink-800 to-pink-900 h-[60px] flex items-center justify-center rounded-full mt-[52px] text-[18px] font-bold tracking-[-0.3px] hover:bg-gradient-to-r hover:from-pink-800 hover:to-pink-900 text-white shadow-custom-btn">Done</a>
                        </div>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <button data-modal-hide="send-copy" className="absolute top-10 right-10" onClick={onClose}>
                        <img src={closeIcon} alt="" />
                    </button>

                </div>
            </div>
            {isOpen && <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>}
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <div id="send-copy" data-modal-backdrop="static" tabIndex="-1" className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 xs:h-[100%] lg:h-[auto] " + (isOpen ? "flex" : "hidden")}>
            <div className="relative w-full max-w-full md:max-w-[620px] xs:h-full md:h-[auto] md:max-h-full bg-white py-16 shadow-2xl xs:px-10 lg:px-20 md:rounded-[20px] animate-fadeInSlide">
                    <div className="flex flex-col items-center">
                        <img src={mailIcon} alt="" />
                        <h2 className="font-bold text-[20px] mt-3">Send a Copy</h2>
                    </div>
                    <button data-modal-hide="send-copy" className="absolute top-10 right-10" onClick={onClose}>
                        <img src={closeIcon} alt="" />
                    </button>
                    <div className="bg-gradient-to-l from-green-800 to-green-500 p-[1px] xs:h-[80px] lg:h-[64px] rounded-[10px] mt-6">
                    <div className="bg-gradient-to-l from-green-200 to-green-100 w-full rounded-[9px] flex h-full">
                            <div className="flex items-end">
                                <img src={rahaGrettingIcon} alt=""/>
                            </div>
                            <div className="flex flex-col justify-center leading-tight xs:px-2 lg:pl-4 flex-1">
                                <p className="font-medium text-[#0F5013] text-[15px]">
                                    Thank you for chatting with us. <br className="xs:hidden lg:block"/>We hope your concerns were addressed.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="email" className="font-semibold mt-9 mb-3">
                            Enter your email below to receive a copy of your conversation.
                        </label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="example@email.com" className="bg-white border-1 border-gray-200 rounded-full w-full h-[52px] font-medium pl-6 focus:border-pink-800 focus:ring focus:ring-pink-100 mb-5"/>
                        {emailError && <p className="text-red-500">{emailError}</p>}
                        <div className="flex items-start">
                            <p className="ms-3 text-[15px] text-gray-800 leading-snug">By emailing a copy of this conversation, you agree to our a  <a  target="_blank" rel="noreferrer" href="https://www.behumane.ai/privacy-policy" className="underline">Privacy Policy</a> for the use of your email address.</p>
                        </div>
                        <button onClick={handleSend} disabled={!isEmailValid} 
                        className={"transition-all duration-300 ease-in-out delay-150 h-[60px] flex items-center justify-center rounded-full mt-[52px] text-[18px] font-bold tracking-[-0.3px] text-white " + (!isEmailValid ? "bg-gray-800" : "bg-gradient-to-l from-pink-800 to-pink-900 shadow-custom-btn")}>Send Email</button>
                    </div>
                </div>
            </div>
            {isOpen && <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>}
        </React.Fragment>
    )
}

export default SendEmailCopyModal