import React from "react";
// import { Doughnut } from "react-chartjs-2";
import {
   Chart as ChartJS,
   ArcElement,
   Tooltip,
   Legend,
} from "chart.js";
import vectorPhyciallyImg from "../../images/vector-phycially.svg";
import vectorSociallyImg from "../../images/vector-socially.svg";
import vectorEmotionallyImg from "../../images/vector-emotionally.svg";
// import vectorMentallyImg from "../../images/vector-mentally.svg";
// import vectorEducationallyImg from "../../images/vector-educationally.svg";
import circleShadowImg from "../../images/circle-shadow.svg";
import dotsImg from "../../images/dots.svg";

// Register necessary chart components
ChartJS.register(ArcElement, Tooltip, Legend);
const centerTextPlugin = {
   id: 'centerText',
   beforeDraw: function (chart) {
      const { width, height, ctx } = chart;
      const centerX = width / 2;
      const centerY = height / 2;

      // Get the data value for the largest segment
      const total = chart.data.datasets[0].data.reduce((sum, val) => sum + val, 0);
      const percentage = Math.round((chart.data.datasets[0].data[0] / total) * 100);

      // Draw the text in the center
      ctx.save();
      ctx.font = 'bold 20px Proxima Nova';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = '#57248E'; // Text color
      ctx.fillText(`${percentage}%`, centerX, centerY); // Display percentage
      ctx.restore();
   },
};

ChartJS.register(centerTextPlugin);

const InSightsTabComponent = ({insightTabData}) => {

   // const options = {
   //    responsive: true,
   //    cutout: '72%',
   //    plugins: {
   //       legend: { display: false },
   //       tooltip: { enabled: false },
   //    },
   //    animation: {
   //       duration: 2000,
   //       easing: 'easeInOutQuad',
   //       animateScale: false,
   //       animateRotate: true,
   //    },
   //    centerTextPlugin: {
   //       text: "test",
   //    },
   // };
   return <div id="insights-tab-content" className="mt-2">
      <div className="hidden" id="breakdown" role="tabpanel" aria-labelledby="breakdown-tab">
      <div className="bg-shade-3 flex xs:flex-col lg:flex-row items-center xs:min-h-[100%] lg:min-h-[316px] relative mb-5 rounded-[20px]">
            <div className="relative lg:min-w-[360px] flex items-center justify-center xs:pt-10 lg:pt-0">
               <img src={vectorEmotionallyImg} className="relative z-[2]" alt="" />
               <img src={circleShadowImg} className="absolute top-0 left-0 right-0 bottom-0 m-auto z-[1]" alt="" />
            </div>
            <span className="absolute top-0 left-0 m-auto z-[0]">
               <img src={dotsImg} alt="" />
            </span>
            <div className="flex-1 xs:px-6 lg:px-[52px] xs:py-10 lg:py-4">
               <h2 className="font-bold text-[24px] mb-4">Emotionally</h2>
               <p className="text-[17px]">
               {insightTabData && insightTabData.breakdown.emotional_development}
               </p>
            </div>
         </div>
         <div className="bg-shade-2 flex xs:flex-col lg:flex-row items-center xs:min-h-[100%] lg:min-h-[316px] relative mb-5 rounded-[20px]">
            <div className="relative lg:min-w-[360px] flex items-center justify-center xs:pt-10 lg:pt-0">
               <img src={vectorSociallyImg} className="relative z-[2]" alt="" />
               <img src={circleShadowImg} className="absolute top-0 left-0 right-0 bottom-0 m-auto z-[1]" alt="" />
            </div>
            <span className="absolute top-0 left-0 m-auto z-[0]">
               <img src={dotsImg} alt="" />
            </span>
            <div className="flex-1 xs:px-6 lg:px-[52px] xs:py-10 lg:py-4">
               <h2 className="font-bold text-[24px] mb-4">Socially</h2>
               <p className="text-[17px]">
               {insightTabData && insightTabData.breakdown.social_development}
               </p>
            </div>
         </div>
         <div className="bg-shade-1 flex xs:flex-col lg:flex-row items-center xs:min-h-[100%] lg:min-h-[316px] relative mb-5 rounded-[20px]">
            <div className="relative lg:min-w-[360px] flex items-center justify-center xs:pt-10 lg:pt-0">
               <img src={vectorPhyciallyImg} className="relative z-[2]" alt="" />
               <img src={circleShadowImg} className="absolute top-0 left-0 right-0 bottom-0 m-auto z-[1]" alt="" />
            </div>
            <span className="absolute top-0 left-0 m-auto z-[0]">
               <img src={dotsImg} alt="" />
            </span>
            <div className="flex-1 xs:px-6 lg:px-[52px] xs:py-10 lg:py-4">
               <h2 className="font-bold text-[24px] mb-4">Physically</h2>
               <p className="text-[17px]">
                  {insightTabData && insightTabData.breakdown.physical_development}
               </p>
            </div>
         </div>
         
      </div>
      <div className="hidden" id="statistics" role="tabpanel" aria-labelledby="statistics-tab">
         <div className="bg-shade-1 flex xs:flex-col lg:flex-row items-center xs:min-h-[100%] lg:min-h-[280px] relative mb-5 rounded-[20px]">
            {/* <div className="relative w-[190px] flex items-center justify-center mx-14 xs:pt-10 lg:pt-0">
               
               <Doughnut data={{
                  type: 'doughnut',
                  datasets: [
                     {
                        data: [40, 60],
                        backgroundColor: [
                           'rgba(118, 25, 141, 1)',
                           'rgba(255, 255, 255, 1)',
                        ],
                        borderColor: [
                           'rgba(118, 25, 141, 1)',
                        ],
                        borderWidth: 1,
                     },
                  ],
               }} options={options} />
            </div> */}
            <div className="flex-1 pl-[40px] xs:px-6 xs:pl-[50px] lg:pr-[52px] xs:py-10 lg:py-4" style={{ textWrap : "balance"}}>
               <h2 className="font-normal text-[24px] mb-4 leading-9"> {insightTabData && insightTabData.by_the_numbers.trend_1.title}</h2>
               <p className="text-[17px] leading-6 font-normal mb-4">
               {insightTabData && insightTabData.by_the_numbers.trend_1.description}
                  {/* 30-40% of adolescents display signs of irritability, particularly during early adolescence. SourceHi! My name is R.H.E.A. Welcome to a new era of digital-age parenting! */}
               </p>
               <p className="text-[16px] font-medium" style={{ color : "#E70793"}}>Source</p>
            </div>
         </div>
         <div className="bg-shade-2 flex xs:flex-col lg:flex-row items-center xs:min-h-[100%] lg:min-h-[280px] relative mb-5 rounded-[20px]">
            {/* <div className="relative w-[190px] flex items-center justify-center mx-14 xs:pt-10 lg:pt-0">
               <Doughnut data={{
                  type: 'doughnut',
                  datasets: [
                     {
                        data: [25,75],
                        backgroundColor: [
                           'rgba(118, 25, 141, 1)',
                           'rgba(255, 255, 255, 1)',
                        ],
                        borderColor: [
                           'rgba(118, 25, 141, 1)',
                        ],
                        borderWidth: 1,
                     },
                  ],
               }} options={options} />
            </div> */}
            <div className="flex-1 pl-[40px] xs:px-6 xs:pl-[50px] lg:pr-[52px] xs:py-10 lg:py-4">
               <h2 className="font-normal text-[24px] mb-4 leading-9"> {insightTabData && insightTabData.by_the_numbers.trend_2.title}</h2>
               <p className="text-[17px] leading-6 font-normal mb-4">
               {insightTabData && insightTabData.by_the_numbers.trend_2.description}
                  {/* 4-6 hours a day is an average time adolescents spend time on screens. */}
               </p>
               <p className="text-[16px] font-medium" style={{ color : "#E70793"}}>Source</p>
            </div>
         </div>
         <div className="bg-shade-3 flex xs:flex-col lg:flex-row items-center xs:min-h-[100%] lg:min-h-[280px] relative mb-5 rounded-[20px]">
            {/* <div className="relative w-[190px] flex items-center justify-center mx-14 xs:pt-10 lg:pt-0">
               <Doughnut data={{
                  type: 'doughnut',
                  datasets: [
                     {
                        data: [60, 40],
                        backgroundColor: [
                           'rgba(118, 25, 141, 1)',
                           'rgba(255, 255, 255, 1)',
                        ],
                        borderColor: [
                           'rgba(118, 25, 141, 1)',
                        ],
                        borderWidth: 1,
                     },
                  ],
               }} options={options} />
            </div> */}
            <div className="flex-1 pl-[40px] xs:px-6 xs:pl-[50px] lg:pr-[52px] xs:py-10 lg:py-4">
               <h2 className="font-normal text-[24px] mb-4 leading-9"> {insightTabData && insightTabData.by_the_numbers.trend_3.title}</h2>
               <p className="text-[17px] leading-6 font-normal mb-4">
               {insightTabData && insightTabData.by_the_numbers.trend_3.description}
                  {/* 60% of adolescents report feeling overwhelmed by the emotional demands of their friendships. */}
               </p>
               <p className="text-[16px] font-medium" style={{ color : "#E70793"}}>Source</p>
            </div>
         </div>
      </div>
   </div>
}
export default InSightsTabComponent;