import React, { useRef } from "react";
import upArrowIcon from "../../images/up-arrow.svg";
import ReactGA from 'react-ga4';


const CheckInInputArea = ({ inputValue, onFormSubmit, isDisabled, onInputChange }) => {
    const textareaRef = useRef(null);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onFormSubmit(e);
        }
    };
    const handleFocus = () => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.event({
                category: 'User Interaction',
                action: 'message_bar_activated',
                label: 'Message Bar Activated'
            });
        }
    };

    const adjustTextarea = (textarea) => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    
        if (textarea.value === '') {
          textarea.style.height = '62px'; 
          textarea.style.borderRadius = '9999px'; 
        } else {
          const borderRadius = textarea.scrollHeight > 80 ? '12px' : '9999px'; 
          textarea.style.borderRadius = borderRadius;
        }
      };

    return  <form onSubmit={onFormSubmit} className={`flex flex-1 flex-wrap  ${isDisabled ? 'bg-[#FFFFFF0D]' : ''}`}>
        <div className="relative flex items-center flex-1">
            <textarea
                ref={textareaRef}
                value={inputValue}
                onChange={onInputChange}
                disabled={isDisabled}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                placeholder="What’s on you mind?"
                onInput={(e)=>adjustTextarea(e.target)}
                className={"block w-full bg-white border border-gray-200 focus:border-pink-800 focus:ring focus:ring-gray-100 resize-none lg:h-[62px] xs:h-[62px] font-medium pl-6 shadow-sm pr-16 rounded-full "+(isDisabled ?'opacity-4':'')}
                style={{paddingTop:"18px", maxHeight:"120px"}}
                ></textarea>
            <button disabled={isDisabled}  style={{background: isDisabled? '#FFFFFF0D' :'' }} type="submit" className={`${isDisabled ? 'opacity-50 cursor-not-allowed  bg-[#FFFFFF0D] opacity-4' : ''}`}>
                <span className="w-[38px] h-[38px] rounded-full bg-gradient-to-t from-pink-800 to-pink-900 absolute right-3 top-0 bottom-0 m-auto flex items-center justify-center shadow-md">
                    <img src={upArrowIcon} alt="" />
                </span>
            </button>
            </div>
        </form>
}

export default CheckInInputArea;