import React, { useState, useEffect } from 'react';

import { useMessages } from '../../hooks';
import InsightsComponent from './InsightsComponent';
import NoInsightsComponent from './NoInsightsComponent';

const InsightsMainComponent = () => {
    const { messages } = useMessages();
    const [isInsight, setIsInsight] = useState(false);

    useEffect(() => {
        console.log(messages.length, "messages.length")
        if (messages && (messages.length === 1 || messages.length === 0)) { // Use strict equality
            setIsInsight(false);
        } else {
            setIsInsight(true);
        }
    }, [messages]);

    return (<>
        {isInsight ? <InsightsComponent /> : <NoInsightsComponent />}
    </>
    )
}

export default InsightsMainComponent