import React, { useEffect } from "react";
import ReactGA from "react-ga4";
// import ChatInterface from "./components/ChatInterface.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import LoginPage from "./components/SignIn.js";
// import LinkCode from "./components/code.js";
// import Welcome from "./components/Welcome.js";
// import Onboard from "./components/Onboard.js";
import Intro from "./components/Intro.js";
import UpgradePlan from "./components/UpgradePlan.js";
import Payment from "./components/Payment.js";
import ConfirmedPlan from "./components/ConfirmedPlan.js";
import ChekInComponent from "./components/checkIn/ChekInComponent.js";
import SignInComponent from "./components/auth/SignInComponent.js";
import SignInCodeComponent from "./components/auth/SignInCodeComponent.js";
// import WelcomeComponent from "./components/WelcomeComponent.js";
// import InsightsComponent from "./components/insights/InsightsComponent.js";
// import NoInsightsComponent from "./components/insights/NoInsightsComponent.js";
import WelcomeMain from "./components/welcome/WelcomeMain.js";
import OnboardMain from "./components/onBoard/OnboardMain.js";

// import WelcomeComponent from "./components/WelcomeComponent.js";
// import InsightsComponent from "./components/insights/InsightsComponent.js";
import InsightsMainComponent from "./components/insights/InsightsMainComponent.js";
// import NoInsightsComponent from "./components/insights/NoInsightsComponent.js";

function App() {
  useEffect(() => {
    // Ensuring GA is only initialized and events are only sent in production
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-R1F0GQ8NSL");
      // Tracking the 'website_accessed' event
      ReactGA.event({
        category: "Engagement",
        action: "website_accessed",
        label: "Landing Page",
      });
    }
  }, []);
  return (
    // <div
    //   className="App flex flex-col justify-center"
    //   style={{
    //     background: "#25164a",
    //   }}
    // >
      <Router>
        {/* <div className="App"> */}
          <Routes>
            {/* <Route path="/" element={<ChatInterface />} /> */}
            <Route path="/" element={<ChekInComponent />} />
            {/* <Route path="/login" element={<LoginPage />} /> */}
            <Route path="/login" element={<SignInComponent />} />
            {/* <Route path="/code" element={<LinkCode />} /> */}
            <Route path="/code" element={<SignInCodeComponent />} />
            {/* <Route path="/welcome" element={<Welcome />} /> */}
            {/* <Route path="/welcome" element={<WelcomeComponent />} /> */}
            {/* <Route path="/onboard" element={<Onboard />} /> */}
            <Route path="/intro" element={<Intro />} />
            <Route path="/upgrade-plan" element={<UpgradePlan />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/confirmed-plan" element={<ConfirmedPlan />} />
            {/* <Route path="/insights" element={<InsightsComponent/>}/>
            <Route path="/no-insights" element={<NoInsightsComponent/>}/> */}
            <Route path="/welcome" element={<WelcomeMain/>} />
            <Route path="/onboard" element={<OnboardMain />} />
            
            <Route path="/insights" element={<InsightsMainComponent/>}/>
          </Routes>
        {/* </div> */}
      </Router>
    // </div>
  );
}

export default App;
