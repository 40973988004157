import React, { useEffect, useRef, useState } from "react";

const IntroPage = ({ onboardingData, onNext }) => {
  const [parenthood, setParenthood] = useState(onboardingData.parenthood);
  const [age, setAge] = useState(onboardingData.age);

  const handleNext = () => {
    onNext({ parenthood, age });
  };
  const parenthoodSelectRef = useRef(null);
  const parenthoodTextRef = useRef(null);
  const ageSelectRef = useRef(null);
  const ageTextRef = useRef(null);

    useEffect(() => {
      if (parenthoodSelectRef.current && parenthoodTextRef.current) {
        const text =
          parenthoodSelectRef.current.options[
            parenthoodSelectRef.current.selectedIndex
          ].text;
        parenthoodTextRef.current.innerText = text;
        const width = parenthoodTextRef.current.offsetWidth;
        parenthoodSelectRef.current.style.width = `${width + 20}px`;
      }
    }, [parenthood]);
  
    useEffect(() => {
      if (ageSelectRef.current && ageTextRef.current) {
        const text =
          ageSelectRef.current.options[ageSelectRef.current.selectedIndex].text;
        ageTextRef.current.innerText = text;
        const width = ageTextRef.current.offsetWidth;
        ageSelectRef.current.style.width = `${width + 20}px`;
      }
    }, [age]);
  return (<>



<div className="flex flex-col min-h-svh bg-purple-50 p-4 h-full">
      <h1 className="text-lg sm:text-xl text-gray-700 text-center mt-10 sm:mt-20 max-w-screen-md items-center justify-center self-center">
        We are very excited you're here! 🙌 Tell us a bit about yourself:
      </h1>

      <main className="flex-grow flex flex-col items-center justify-center  md:max-w-md mx-auto w-full p-4 overflow-x-hidden md:overflow-x-visible">
        <form
          // onSubmit={handleSubmit}
          className="max-w-screen-sm min-w-full flex flex-col gap-4 mx-auto justify-between items-center flex-grow"
        >
          <div></div>
          <div className="text-left mb-8 w-full flex flex-col md:scale-150 items-center justify-center text-center">
            <p className="text-3xl md:text-xl text-gray-800 w-full">
              I am a{" "}
              <select
                ref={parenthoodSelectRef}
                value={parenthood}
                onChange={(e) => setParenthood(e.target.value)}
                className="text-pink-600 font-bold bg-transparent border-none appearance-none"
                style={{ width: "auto" }}
              >
                <option value="" disabled hidden>
                  select 🔽
                </option>
                <option value="father">father 👨</option>
                <option value="mother">mother 👩</option>
                <option value="stepfather">stepfather 👨</option>
                <option value="stepmother">stepmother 👩</option>
                <option value="legal_guardian">legal guardian 👤</option>
                <option value="something_else">something else 👤</option>
                <option value="prefer_not_to_say">
                  prefer not to say 🤐
                </option>
              </select>
              <span
                ref={parenthoodTextRef}
                style={{
                  visibility: "hidden",
                  position: "absolute",
                  whiteSpace: "nowrap",
                }}
                className="text-pink-600 font-bold"
              />
              {" "}who is{" "}
              <select
                ref={ageSelectRef}
                value={age}
                onChange={(e) => setAge(e.target.value)}
                className="text-pink-600 font-bold bg-transparent border-none appearance-none"
                style={{ width: "auto" }}
              >
                <option value="" disabled hidden>
                  select 🔽
                </option>
                <option value="18-24">18-24</option>
                <option value="25-34">25-34</option>
                <option value="35-44">35-44</option>
                <option value="45-54">45-54</option>
                <option value="55-64">55-64</option>
                <option value="65+">65+</option>
                <option value="prefer_not_to_say">prefer not to say</option>
              </select>
              <span
                ref={ageTextRef}
                style={{
                  visibility: "hidden",
                  position: "absolute",
                  whiteSpace: "nowrap",
                }}
                className="text-pink-600 font-bold"
              />
              {" "}years old.
            </p>
          </div>
{/* 
          {error && (
            <p className="text-red-500 text-sm text-center mb-4">{error}</p>
          )} */}

          <div className="flex flex-col justify-center items-center w-full mx-auto mb-10">
            <p className="text-xs text-gray-600 mb-6  px-4 sm:px-0">
              Your responses help us address your questions and concerns better
              by personalizing RHEA to your needs. Your information is kept
              private and secure.
            </p>

            <button
              type="submit"
              disabled={!parenthood || !age}
              onClick={handleNext}
              className={"transition-all duration-300 ease-in-out delay-150 h-[64px] flex items-center justify-center rounded-full my-6 text-[24px] tracking-[-0.3px] w-80 text-white ms-auto w-full " +(!parenthood || !age ? 'bg-gray-800 cursor-not-allowed':'from-pink-800 to-pink-900 bg-gradient-to-l shadow-lg')}
            >
             Continue chatting
            </button>
            {/* <button
              type="submit"
              disabled={!parenthood || !age}
              onClick={handleNext}
              className="w-full bg-gradient-to-br from-purple-500 to-pink-500 text-white font-bold py-3 px-4 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
            >
               Continue chatting
            </button> */}
          </div>
        </form>
      </main>

      <footer className="mt-8 px-4 sm:px-8 md:px-16 mb-4">
        <div className="flex justify-between text-xs text-gray-600 max-w-md mx-auto w-full">
          <a
            href="mailto:support@behumane.co"
            className="hover:text-gray-900"
          >
            Need help?
          </a>
          <a
            href="https://www.behumane.ai/privacy-policy"
            className="hover:text-gray-900"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </footer>
    </div> 
    </>
  );
};

export default IntroPage;
