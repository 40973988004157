// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB6Zxrl2VjrdevUoLYQ5EPrIzV40x7FJgg",
  authDomain: "be-human-6a037.firebaseapp.com",
  projectId: "be-human-6a037",
  storageBucket: "be-human-6a037.firebasestorage.app",
  messagingSenderId: "834480730417",
  appId: "1:834480730417:web:206d69cd0afd151fa14010",
  measurementId: "G-PLB114C1P9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

export default app;